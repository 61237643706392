import React from 'react'
import LayoutStatic from '../../../components/LayoutStatic';
import { Link } from 'gatsby';

const style = {
  button: {
    marginTop: '10px',
    marginRight: '10px',
    width: '45%'
  }
  
}

const IndexPage = () => {
  return (
    <LayoutStatic>
      <div className="card" style={{width: '100%', margin: 'auto', marginTop: '20px',}} >
       
        <div className="card-body">

        <a href="https://service.moronline.me/backoffice/campaign/154">
          <img src="/img/queue1.png" className="card-img-top bootstrap-card-img-top" alt="..." />
        </a>

        </div>

      </div>
    </LayoutStatic>
  );
}

export default IndexPage

